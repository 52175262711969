<div class="certified py-5">
  <div class="image text-center">
    <img
      src="../../../../assets/home/Services_Partner.png"
      alt="Airtable service partner"
      class="img-fluid"
    />
  </div>
  <div class="certifications">
    <h3 class="text-center">Proudly Certified By :</h3>
    <div
      class="boxes container d-flex justify-content-center justify-content-lg-between justify-content-md-between align-items-center flex-wrap"
    >
      <div class="box">
        <img
          src="../../../../assets/home/bronze_partner__1_ 1.png"
          alt="SmartSuite"
          (click)="
            cetificationLinks(
              'https://directory.smartsuite.com/hire-an-expert/geekyair'
            )
          "
        />
        <h6>SmartSuite</h6>
      </div>
      <div class="box">
        <img
          src="../../../../assets/home/op_appicon_circle_purple 1.png"
          alt="OpenPhone"
          (click)="
            cetificationLinks(
              'https://www.openphone.com/?utm_source=partnerstack&utm_medium=affiliate&utm_campaign=mohamedswellam8273&utm_content=standard_affiliate&pscd=get.openphone.com&ps_partner_key=bW9oYW1lZHN3ZWxsYW04Mjcz&utm_source=partnerstack&utm_medium=affiliate&utm_campaign=mohamedswellam8273&utm_content=standard_affiliate&pscd=get.openphone.com&ps_partner_key=bW9oYW1lZHN3ZWxsYW04Mjcz&ps_xid=uWAgDZfg5LpuJw&gsxid=uWAgDZfg5LpuJw&gspk=bW9oYW1lZHN3ZWxsYW04Mjcz'
            )
          "
        />
        <h6>OpenPhone</h6>
      </div>
      <div class="box">
        <img
          src="../../../../assets/home/zapier-logo__2_ 1.png"
          alt="zapier"
          (click)="cetificationLinks('https://zapier.com/experts/geekyair')"
        />
        <h6>Zapier</h6>
      </div>
      <div class="box">
        <img
          src="../../../../assets/home/meetgeek3 1.png"
          alt="meetgeek"
          (click)="
            cetificationLinks(
              'https://meetgeek.ai/?source=mohamedswellam8273&utm_campaign=partnerstack&utm_medium=partner&utm_source=mohamedswellam8273&ps_partner_key=bW9oYW1lZHN3ZWxsYW04Mjcz&source=mohamedswellam8273&ps_partner_key=bW9oYW1lZHN3ZWxsYW04Mjcz&ps_xid=AQG6sJBN0FoLfy&gsxid=AQG6sJBN0FoLfy&gspk=bW9oYW1lZHN3ZWxsYW04Mjcz'
            )
          "
        />
        <h6>MeetGeek</h6>
      </div>
    </div>
  </div>
</div>
