<div class="founder">
  <div class="container">
    <div class="row justify-content-center justify-content-lg-start justify-content-md-start">
      <div class="col-md-5">
        <div class="title d-flex justify-content-center mb-5">
          <h4 class="text-center py-4">OUR FOUNDER</h4>
        </div>
        <div class="body mb-5">
          <p class="text-center">
            <span>Mohamed Swellam</span> started working as a No-Code/Low-Code
            expert in 2020. Over the years became a Top Rated Plus freelancer on
            Up work, with more than 50 clients, and 2000+ h worked. In 2023, we
            decided to expand our services to include Web Development, Media
            Marketing, and Content Creation. We started off as a small agency on
            Up work and in 2023 we had our first office with over 20 employees
            working in several fields.
          </p>
        </div>
        <div
          class="foot d-flex justify-content-center align-items-center flex-column"
        >
          <p>Mohamed Swellam</p>
          <p>CEO & Founder Of GeekyAir</p>
        </div>
      </div>
    </div>
  </div>
</div>
