<div class="testimonials">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h4 class="text-center mb-5">Testimonials</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 banner py-4">
        <p-carousel
          [value]="paragraphs"
          [numVisible]="1"
          [numScroll]="1"
          [circular]="true"
          [responsiveOptions]="responsiveOptions"
          [showIndicators]="false"
          [showNavigators]="true"
          [autoplayInterval]="3000"
        >
          <ng-template let-product pTemplate="item">
            <p class="text-center m-0 p-5">{{ product.para }}</p>
            <p class="text-center client">
              <span>{{ product.client }}</span>
            </p>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </div>
</div>
