<main
  style="transition: 0.5s"
  class="main-wrapper"
  [style.opacity]="isServer ? 0 : 1"
>
  <header #nav>
    <app-navbar [navbarBackgroundColor]="darkClass"></app-navbar>
  </header>
  <section>
    <router-outlet></router-outlet>
  </section>
  <footer>
    <app-footer></app-footer>
  </footer>
</main>
