<div class="main-services py-5">
  <div class="title">
    <h3 class="text-center">Digital Brilliance, Everywhere Presence</h3>
  </div>
  <div class="boxes container">
    <div
      routerLink="/nocode-lowcode"
      class="lowcode-box d-flex flex-column align-items-center py-5"
    >
      <h6>Low code/No code</h6>
      <img src="../../../../assets/home/nocode.png" alt="lowcode" />
    </div>
    <div
      routerLink="/web-development"
      class="coding-box d-flex flex-column align-items-center py-5"
    >
      <h6>Web Development</h6>
      <img src="../../../../assets/home/code.png" alt="coding" />
    </div>
    <div
      routerLink="/ui-ux"
      class="uiux-box d-flex flex-column align-items-center py-5"
    >
      <h6>UX/UI Designer</h6>
      <img src="../../../../assets/home/uiux.png" alt="uiux" />
    </div>
  </div>
</div>
