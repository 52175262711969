import { Component } from '@angular/core';
import { CertifiedComponent } from './certified/certified.component';
import { MainServicesComponent } from './main-services/main-services.component';
import { WhyGeekyairComponent } from './why-geekyair/why-geekyair.component';
import { IntroComponent } from './intro/intro.component';
import { FounderComponent } from './founder/founder.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { FAQComponent } from './faq/faq.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CertifiedComponent,
    MainServicesComponent,
    WhyGeekyairComponent,
    IntroComponent,
    FounderComponent,
    TestimonialsComponent,
    FAQComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {}
