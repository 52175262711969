<div class="why py-5">
  <div class="container title">
    <h2 class="text-center">Why GeekyAir ?</h2>
  </div>
  <div class="banner container px-5 py-4 mb-5">
    <p-carousel
      [value]="paragraphs"
      [numVisible]="1"
      [numScroll]="1"
      [circular]="true"
      [responsiveOptions]="responsiveOptions"
      [showIndicators]="false"
      [showNavigators]="false"
      [autoplayInterval]="3000"
    >
      <ng-template let-product pTemplate="item">
        <p class="text-center m-0">{{ product }}</p>
      </ng-template>
    </p-carousel>
  </div>
  <div class="learn-more d-flex justify-content-end container mb-5">
    <button
      routerLink="/about-us"
      class="btn d-flex justify-content-center align-items-center gap-2"
    >
      <p class="m-0">Learn more</p>
      <img src="../../../../assets/home/arrow 1.png" alt="arrow" />
    </button>
  </div>
</div>
