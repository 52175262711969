<div class="faq">
  <div class="container">
    <h4 class="pb-5 mb-5">FQA</h4>
    <hr />
    <div class="row">
      <p-accordion [activeIndex]="0">
        <p-accordionTab
          tabStyleClass="acc"
          header="WHAT SERVICES OR PRODUCTS DO YOU OFFER?"
        >
          <p>
            We provide a variety of services such as web development,
            low-code/no-code development, and UI/UX design
          </p>
        </p-accordionTab>
        <p-accordionTab
          tabStyleClass="acc"
          header=" WHAT MAKES YOU DIFFERENT THAN OTHER COMPANIES? "
        >
          <p>
            We utilize platforms such as Zapier, SmartSuite, Airtable, Openphone
            and others to provide comprehensive No-Code/Low-Code solutions and
            we aim to become the go-to company for all your business needs
            pioneering the future with curiosity, Integrity and sincerity to
            earn the trust of our clients and Sustainability to fulfill our
            responsibilities.
          </p>
        </p-accordionTab>
        <p-accordionTab
          tabStyleClass="acc"
          header=" DO YOU HAVE ANY CERTIFICATIONS / PARTNERSHIPS ? "
        >
          <p>
            We are Proudly Certified by: <br />
            <br />

            <strong> Zapier:</strong> a powerful no-code automation tool that
            can transform your business <br />

            <strong>SmartSuite:</strong> an ultimate platform for managing your
            team's work, including goals, processes, projects, tasks, documents,
            and chat. <br />

            <strong>MeetGeek:</strong> a powerful video conferencing and webinar
            platform that can help you connect with your audience <br />

            <strong>OpenPhone:</strong> a cloud-based phone system that can help
            you stay connected with your team and customers <br />
          </p>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>
