import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CarouselModule } from 'primeng/carousel';

@Component({
  selector: 'app-why-geekyair',
  standalone: true,
  imports: [CarouselModule, RouterLink],
  templateUrl: './why-geekyair.component.html',
  styleUrl: './why-geekyair.component.scss',
  host: { ngSkipHydration: 'true' },
})
export class WhyGeekyairComponent implements AfterViewInit {
  paragraphs: string[] = [];
  responsiveOptions: any[] | undefined;

  constructor() {
    this.paragraphs = [
      'Dedicated to providing excellent customer service and establishing long-term relationships with clients..',
      'A team of skilled and experienced experts committed to delivering quality results.',
      'We provide a variety of services such as UX/UI design, low-code development, web development, and social media management.',
    ];
    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }
  @ViewChild('container') container!: ElementRef;

  ngOnInit() {}
  ngAfterViewInit(): void {}
}
