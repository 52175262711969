import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from './shared/footer/footer.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FooterComponent, NavbarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, DoCheck, AfterViewInit {
  isServer: boolean = true;
  darkClass: string = '';
  @ViewChild('nav') nav!: ElementRef;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log(event.url);
        if (event.url !== '/home' && event.url !== '/') {
          this.darkClass = 'rgba(0, 0, 0, 1)';
        } else {
          this.darkClass = '';
        }
      }
    });
  }
  ngAfterViewInit(): void {}
  ngDoCheck(): void {}
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.isServer = false;
      });
    }
  }
}
