import { Component } from '@angular/core';

@Component({
  selector: 'app-founder',
  standalone: true,
  imports: [],
  templateUrl: './founder.component.html',
  styleUrl: './founder.component.scss'
})
export class FounderComponent {

}
