<div class="footer">
  <div class="overlay"></div>
  <section class="py-5">
    <div class="container">
      <div class="texts">
        <ul
          class="items d-flex justify-content-start align-items-start d-flex flex-wrap gap-4"
        >
          <li routerLink="/about-us">About Us</li>
          <li (click)="scrollintView()">Services</li>
          <li routerLink="/contact-us">Contact Us</li>
        </ul>
        <hr class="line-primary" />
      </div>
      <div
        class="py-3 Copyright d-flex justify-content-between align-items-center gap-5 flex-wrap"
      >
        <div class="">
          <p>Copyright 2024 All Rights reserved to GeekyAir</p>
        </div>
        <div class="icons d-flex gap-3 flex-wrap">
          <div>
            <a href="https://www.facebook.com/geekyair" target="_blank">
              <img src="../../../assets/footer/facebook.png" alt="facebook" />
            </a>
          </div>
          <div>
            <a href="https://twitter.com/MohamedSwellam" target="_blank">
              <img src="../../../assets/footer/twitter.png" alt="twitter" />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/geekyair?igshid=YmMyMTA2M2Y%3D"
              target="_blank"
            >
              <img src="../../../assets/footer/instagram.png" alt="instagram" />
            </a>
          </div>
          <div>
            <a href="https://www.youtube.com/@GeekyAir" target="_blank">
              <img src="../../../assets/footer/youtube.png" alt="youtube" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
