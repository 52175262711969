import { Component, ViewEncapsulation } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';

@Component({
  selector: 'app-testimonials',
  standalone: true,
  imports: [CarouselModule],
  templateUrl: './testimonials.component.html',
  styleUrl: './testimonials.component.scss',
  host: { ngSkipHydration: 'true' },
  encapsulation: ViewEncapsulation.None,
})
export class TestimonialsComponent {
  paragraphs: { para: string; client: string }[] = [];
  responsiveOptions: any[] | undefined;

  constructor() {
    this.paragraphs = [
      {
        para: 'We had issues with our ATS not sending all of our contacts to our CRM. We had a process in place that required us to create 150 new zaps every month but Mohamed was able to create 1 Zap for us that saved us countless hours every month. It was eye opening to realize how much we dont know and how helpful an expert can be.',
        client: 'Tyler Simmonds, Windermere',
      },
      {
        para: 'Had a great experience with Mohamed.They understood exactly what I needed and made my vision come to life!',
        client: 'Matt Clark, Peachtree Debt Relief',
      },
      {
        para: 'Mohamed has always been a problem solver to us,he is very resourceful and knows how to create zaps and systems for our suited CRM.He is always able to resolve any issues. He is responsive,not expensive and straight to the point.',
        client: 'Dorian Roberto Ramirez Sosa, Direct Mediation Services',
      },
      {
        para: 'Mohammad has been helping my business for over three years.He is amazing and I cannot praise him enough for the work he has done for us.Mohammed with the help of Zapier has help automate most of my business processes.He is always a complete professional who responds back in a timely fashion.A huge plus is how easy it is to understand his English.He has come up with many great solutions to my problems.I would highly recommend his services.',
        client: 'Kevin Boehning, Island wide palm trees',
      },
    ];
    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }
}
