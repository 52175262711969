<div class="intro">
  <div class="container">
    <div class="row implement mb-5">
      <div class="col-md-4 info">
        <h1 class="line-height">we</h1>
        <h1 class="lh-1">implement</h1>
      </div>
    </div>
    <div class="row animation">
      <div class="col-md-12 ms-5">
        <div class="paragraph-container mx-5">
          <marquee
            class="banner d-flex flex-column"
            behavior="scroll"
            direction="up"
            height="40px"
            scrolldelay="200"
          >
            <p>No code/Low code</p>
            <p>Scripting</p>
            <p>SmartSuite implementation</p>
            <p>UX/UI Designer</p>
            <p>OpenPhone Implementation</p>
            <p class="m-0">Web Development</p>
          </marquee>
        </div>
      </div>
    </div>
    <div class="row proud">
      <div class="col-md-12 text-center">
        <h2 class="text-uppercase">Certified and proud to share</h2>
        <h2 class="text-uppercase">important news</h2>
      </div>
    </div>
  </div>
</div>
