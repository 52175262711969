import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  {
    path: 'about-us',
    loadComponent: () =>
      import('./components/about/about.component').then(
        (c) => c.AboutComponent
      ),
  },
  {
    path: 'contact-us',
    loadComponent: () =>
      import('./components/join-us/join-us.component').then(
        (c) => c.JoinUsComponent
      ),
  },
  {
    path: 'web-development',
    loadComponent: () =>
      import(
        './components/home/main-services/web-development/web-development.component'
      ).then((c) => c.WebDevelopmentComponent),
  },
  {
    path: 'ui-ux',
    loadComponent: () =>
      import('./components/home/main-services/ui-ux/ui-ux.component').then(
        (c) => c.UiUxComponent
      ),
  },
  {
    path: 'nocode-lowcode',
    loadComponent: () =>
      import(
        './components/home/main-services/nocode-low-code/nocode-low-code.component'
      ).then((c) => c.NocodeLowCodeComponent),
  },
];
