<nav class="container position-relative z-3">
  <div
    class="row position-fixed nav py-3"
    [style.background-color]="
      navbarBackgroundColor ? navbarBackgroundColor : ''
    "
  >
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <div class="image d-flex justify-content-center">
        <img
          routerLink="/home"
          src="../../../assets/home/logo.png"
          alt="logo"
        />
      </div>
    </div>
    <div class="col-md-4 d-flex justify-content-center align-items-center">
      <ul class="d-flex justify-content-end gap-5">
        <li routerLink="/about-us">about us</li>
        <li (click)="scrollintView()">services</li>
        <li routerLink="/contact-us">contact us</li>
      </ul>
    </div>
  </div>
</nav>
