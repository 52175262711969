import { Component } from '@angular/core';

@Component({
  selector: 'app-certified',
  standalone: true,
  imports: [],
  templateUrl: './certified.component.html',
  styleUrl: './certified.component.scss',
})
export class CertifiedComponent {
  cetificationLinks(link: string) {
    window.open(link, '_blank');
  }
}
