<section>
  <app-intro></app-intro>
</section>
<section>
  <app-certified></app-certified>
</section>
<section id="services">
  <app-main-services></app-main-services>
</section>
<section>
  <app-why-geekyair></app-why-geekyair>
</section>
<section>
  <app-founder></app-founder>
</section>
<section>
  <app-testimonials></app-testimonials>
</section>
<section>
  <app-faq></app-faq>
</section>
